<template>
  <div v-if="this.showComponents">
    <van-nav-bar title="我的任务服务费" left-arrow @click-left="onClickLeft" @click-right="onClickRight"> </van-nav-bar>
    <div class="navBar flexBox">
      <div @click="showDatetimePicker = !showDatetimePicker">
        <span style="color: #666"> {{ nowDate }}</span>
        <van-icon color="#666" name="arrow-down" />
      </div>
      <div style="color: #666" v-if="list[0] && list[0].totalPrice !== null">
        合计: <span> {{ computedAll() }} 元</span>
      </div>
    </div>
    <div class="flexBox banner">
      <div>
        <div class="container">
          <van-icon class="warning" name="warning" />
          <div v-if="list[0] && list[0].totalPrice !== null">任务审核通过之后才能拿到服务费哦!</div>
          <div>待审核任务会在7天内审核!</div>
        </div>
        <div style="color: #999" @click="showTaskPicker = !showTaskPicker">
          <!-- <div class="iconBox">
            <span><van-icon name="filter-o" /></span>
            <van-icon name="wap-nav" class="wap-nav" />
          </div> -->
          <div :class="this.taskStatusPicker.includes(null) && this.taskPicker.includes(null) ? '' : 'screenedColor'">
            <span style="position: absolute; right: 0; top: 0">
              <span style="position: relative; right: -2px">筛选</span>
              <van-icon name="play" style="transform: rotate(90deg)" />
            </span>
          </div>
        </div>
      </div>
    </div>
    <van-list
      :style="{ height: height, overflow: 'auto' }"
      v-model="loading"
      :finished="finished"
      :finished-text="this.list.length === 0 ? '' : '已加载全部'"
      @load="getList"
      ><div v-for="item in list" :key="item.id" @click="toDetails(item)">
        <!--  v-if="item.auditStatus !== null"   待定↓ -->
        <div style="background: #fff; padding: 0 4%; box-shadow: inset 0px -1px 0px 0px rgba(237, 237, 237, 1)">
          <div class="cardBox">
            <div class="container">
              <div class="title" style="display: flex; justify-content: space-between">
                <span>{{ item.taskName }}: {{ item.taskSubName }}</span>
                <div class="tips" :style="{ color: tipsColor(item.auditStatus), 'text-align': 'right' }">
                  <div v-if="item.reportPrice !== null">
                    +
                    {{
                      item.auditStatus === 0 || item.auditStatus === 2 ? '0.00' : Number(item.reportPrice).toFixed(2)
                    }}
                    元
                  </div>
                  <div style="font-size: 12px; text-align: right; position: absolute; right: 0">
                    {{ computedStatus(item.auditStatus) }}
                  </div>
                </div>
              </div>
              <div class="warningB" v-if="item.taskClass == 2">B</div>
              <div class="warningP" v-else>P</div>
              <div style="margin-bottom: 6px">
                {{ item.terminalName }}
              </div>
              <div style="margin-bottom: 4px">
                {{ computedTime(item.reportTime) }}
              </div>
              <div v-if="item.reportPrice !== null">任务价值 : {{ Number(item.reportPrice).toFixed(2) }} 元</div>
            </div>
          </div>
        </div>
      </div>
      <van-empty v-if="this.list.length === 0">
        <div style="color: #ccc; font-size: 12px; text-align: center">
          <div style="font-size: 14px">没有找到任何记录</div>
          <div>试试查询其他的吧</div>
        </div>
      </van-empty>
    </van-list>

    <!-- 筛选选择器 -->
    <van-action-sheet v-model="showTaskPicker" title="">
      <div class="contentShowTaskPicker">
        <div class="topTitle">筛选任务</div>
        <div class="titlePicker">任务类型</div>
        <ThisTabs v-model="taskPicker" title="title" code="code" key1="code" :list="taskPickList"> </ThisTabs>
        <!-- <van-tabs v-model="taskPicker" type="card">
          <van-tab
            v-for="item in taskPickList"
            :key="item.code"
            :title="item.title"
            :name="item.code"
          ></van-tab>
        </van-tabs> -->
        <div class="titlePicker">审核状态</div>
        <ThisTabs v-model="taskStatusPicker" title="title" code="code" key1="code" :list="taskStatusPickList">
        </ThisTabs>
        <!-- <van-tabs v-model="taskStatusPicker" type="card" class="status">
          <van-tab title="全部" :name="null"></van-tab>
          <van-tab title="待审核" :name="0"></van-tab>
          <van-tab title="审核通过" :name="1"></van-tab>
          <van-tab title="审核不通过" :name="2"></van-tab>
        </van-tabs> -->
        <div class="btnBox">
          <van-button type="default" class="fir" @click="reset"> 重置 </van-button>
          <van-button type="primary" class="sec" @click="checkState()"> 确定 </van-button>
        </div>
      </div>
    </van-action-sheet>
    <!-- 时间选择器 -->
    <van-action-sheet v-model="showDatetimePicker" title="">
      <div class="content">
        <van-datetime-picker
          v-model="currentDate"
          type="year-month"
          :min-date="minDate"
          :max-date="maxDate"
          :formatter="formatter"
          @confirm="confirm"
          @cancel="cancel"
        />
      </div>
    </van-action-sheet>
  </div>
</template>
<script>
import moment from 'moment'
import ThisTabs from '@/components/sanmeUse/ThisTabs.vue'
import { listBaseTask, myTask } from '@/api/api.js'
export default {
  components: {
    ThisTabs
  },
  data() {
    return {
      nowDate: moment(new Date()).format('YYYY[年]MM[月]'), //左上角的日期
      nowDateForData: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), //左上角的日期但是这个用于给后端传值
      minDate: new Date(2022, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(),
      showDatetimePicker: false, //展示日期选择器
      showTaskPicker: false, //展示筛选选择器
      taskPicker: [null], //筛选内容的值
      taskPickList: [], //筛选内容的内容数组
      taskStatusPicker: [null], //审核状态的值
      taskStatusPickList: [
        { title: '全部', code: null },
        { title: '待审核', code: 0 },
        { title: '审核通过', code: 1 },
        { title: '审核不通过', code: 2 },
        { title: '甄别结束', code: 3 }
      ], //筛选状态的内容数组
      list: [], //任务list
      loading: false, //加载中
      finished: false, //加载结束
      pageNum: 1,
      pageSize: 20,
      total: 0, //总数量
      showComponents: false,
      height: '200px'
    }
  },
  methods: {
    onClickLeft() {
      location.href = 'javascript:history.back();'
      // Toast("返回");
    },
    onClickRight() {
      // Toast("按钮");
    },
    toDetails(item) {
      this.$store.commit('auditDetailsItem', item)
      this.$router.push(`/auditDetails/${item.id}`)
    },
    //获取列表   list懒加载
    getList(screen) {
      this.finished = true
      this.showTaskPicker = false
      // console.log()
      let taskPicker = this.taskPicker.includes(null)
        ? this.taskPickList
            .filter((item) => {
              return item.title !== '全部'
            })
            .map((item) => {
              if (item.title !== '全部') {
                return item.code
              } else {
                return ''
              }
            })
        : this.taskPicker
      let taskStatusPicker = this.taskStatusPicker.includes(null) ? [0, 1, 2, 3] : this.taskStatusPicker
      myTask({
        reportDate: this.nowDateForData,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        taskCodeList: taskPicker,
        auditStatusList: taskStatusPicker
        // taskCodeList: this.taskPicker,
        // auditStatusList: this.taskStatusPicker
      }).then((res) => {
        this.pageNum++
        this.showComponents = true
        this.total = res.data.total
        // this.total = 50
        if (!this.getUrlData()) {
          this.list = []
          this.finished = true
          return
        } else {
          if (!this.getUrlData().userOpenId) {
            this.list = []
            this.finished = true
            return
          }
        }
        if (screen) {
          this.list = res.data.data
          // this.pageSize = 10
        } else {
          this.list = [...this.list, ...res.data.data]
          // this.pageSize = 20
          // this.list = [...this.list, ...res.data.data,...this.list]
        }
        // 加载状态结束
        this.loading = false
        // 数据全部加载完成
        // console.log(this.total, this.list.length)
        this.finished = false
        if (this.list.length >= this.total || res.data.data.length === 0) {
          this.finished = true
        }
      })
    },
    //时间选择器点击确定
    confirm(value) {
      this.pageNum = 1
      this.nowDate = moment(value).format('YYYY[年]MM[月]')
      this.nowDateForData = moment(value).format('YYYY-MM-DD HH:mm:ss')
      this.getList(true)
      this.showDatetimePicker = false
    },
    //时间选择器点击取消
    cancel() {
      this.showDatetimePicker = false
    },
    //时间选择器默认功能勿动
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`
      } else if (type === 'month') {
        return `${val}月`
      }
      return val
    },
    //获取筛选任务的tabs
    listBaseTask() {
      return listBaseTask().then((res) => {
        if (res.data.success) {
          res.data.data.unshift({ code: null, title: '全部' })
          this.taskPickList = res.data.data
        }
      })
    },
    //重置
    reset() {
      this.pageNum = 1
      this.taskStatusPicker = [null]
      this.taskPicker = [null]
      this.getList(true)
    },
    //计算总钱数
    computedAll() {
      if (this.list.length !== 0) {
        return Number(this.list[0].totalPrice).toFixed(2)
      } else {
        return 0
      }
    },
    //计算每一条的日期
    computedTime(time) {
      return moment(time).format('YYYY-MM-DD HH:mm:ss')
    },
    //计算状态
    computedStatus(auditStatus) {
      switch (auditStatus) {
        case 0:
          return '待审核'
        case 1:
          return '审核通过'
        case 2:
          return '审核不通过'
        case 3:
          return '甄别结束'
      }
    },
    //计算颜色
    tipsColor(auditStatus) {
      switch (auditStatus) {
        case 0:
          return '#333333'
        case 1:
          return '#52C41A'
        case 2:
          return '#FAAD14'
        case 3:
          return '#FAAD14'
      }
    },
    //获取url传参
    getUrlData() {
      var url = window.location.search
      var obj = {}
      var reg = /[?&][^?&]+=[^?&]+/g
      var arr = url.match(reg)
      if (arr) {
        arr.forEach(function (item) {
          var tempArr = item.substring(1).split('=')
          var key = decodeURIComponent(tempArr[0])
          var val = decodeURIComponent(tempArr[1])
          obj[key] = val
        })
        return obj
      }
    },
    checkState() {
      this.pageNum = 1
      this.getList(true)
    }
  },
  created() {
    this.height = document.documentElement.clientHeight - 64 - 44 - 40 + 'px'
    if (this.getUrlData()) {
      this.$store.commit('urlInfo', this.getUrlData())
    }
    this.nowDateForData = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
  },
  mounted() {
    this.listBaseTask().then(() => {
      this.getList()
    })
  }
}
</script>
<style lang="less" scoped>
.flexBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navBar {
  font-size: 14px;
  padding: 0 4%;
  background: #fff;
  height: 40px;
  line-height: 40px;
}
.banner {
  padding: 10px 4% 10px 4%;
  font-size: 14px;
  line-height: 22px;
  color: #faad14;
  > div {
    position: relative;
    width: 100%;
  }
  .container {
    position: relative;
    padding-left: 27px;
    .warning {
      position: absolute;
      left: 2px;
      top: 3px;
    }
  }
  .iconBox {
    position: absolute;
    top: 0;
    right: -13px;
    font-size: 20px;
  }
  .wap-nav {
    transform: scale(0.5);
    position: relative;
    right: 13px;
    top: 3px;
  }
}
.contentShowTaskPicker {
  padding: 0px 4% 25px 4%;
}
/deep/ .van-tabs--card > .van-tabs__wrap {
  height: auto;
}
/deep/ .van-tabs__nav {
  justify-content: flex-start;
  flex-wrap: wrap;
  height: auto;
  border: none;
}
/deep/ .van-tab {
  width: 90px;
  margin-right: 10px;
  flex: 0 1 auto;
  margin-bottom: 10px;
  border: none;
  background: #e4e4e4;
  color: #666;
  padding: 5px 10px;
  border-radius: 2px;
}
/deep/ .van-tab--active {
  color: #0066ff !important;
  background: #dfecff !important;
}
// .status {
//   /deep/ .van-tab {
//     width: 70px;
//     margin-right: 10px;
//     flex: 0 1 auto;
//     margin-bottom: 10px;
//     border: none;
//     // background: #ff0;
//   }
// }
.titlePicker {
  margin: 10px 0;
  height: 30px;
  font-size: 14px;
  color: #999999;
  line-height: 30px;
}
.topTitle {
  margin-top: 15px;
  margin-bottom: 20px;
  height: 30px;
  font-size: 16px;
  color: #333333;
  line-height: 30px;
  font-weight: 900;
}
.btnBox {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  button {
    height: 44px;
    flex: 1;
  }
  .fir {
    background: #ffffff;
    color: #1890ff;
    border: 1px solid #24adfe;
    border-radius: 22px 0px 0px 22px;
  }
  .sec {
    background: #24adfe;
    border: 1px solid #24adfe;
    border-radius: 0px 22px 22px 0px;
  }
}
.cardBox {
  padding: 15px 0 20px 0;
  color: #666;
  .container {
    position: relative;
    padding-left: 27px;
    .warningB,
    .warningP {
      position: absolute;
      left: 0px;
      top: 0;
      width: 18px;
      height: 18px;
      color: #ffffff;
      text-align: center;
      line-height: 18px;
      font-size: 12px;
      border-radius: 2px;
    }
    .warningB {
      background: #1890ff;
    }
    .warningP {
      background: #52c41a;
    }
    .tips {
      // position: absolute;
      // top: 0;
      // right: 0;
      font-weight: normal;
      width: auto;
      flex-shrink: 0;
    }
  }
  .title {
    color: #333333;
    font-weight: 700;
    margin-bottom: 16px;
  }
}
.screenedColor {
  color: #24adfe;
}
</style>

<template>
  <div>
    <div class="outBox">
      <div v-for="(item, index) in list" :key="item[key1]">
        <div
          @click="clickTabs(item, index)"
          :class="computedClass(item) ? 'active default' : 'default'"
        >
          <div class="tabs">
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    msg: { type: Array, default: [] }, //传入的双向绑定数据 数组 会返回被选中的数组的code
    title: { type: String, default: "" }, //要显示的标题
    code: { type: String, default: "title" }, //要存储的数据 不传就是title
    key1: { type: String, default: "code" }, //唯一的键值 不传默认用code
    list: { type: Array, default: [] }, //要循环渲染的数组
  },
  model: {
    prop: "msg",
    event: "changemsg",
  },
  methods: {
    //点击标签触发 绑定msg   //计算tabs的返回结果
    clickTabs(item) {
      let array = [];
      // console.log(item[this.code]);
      if (item[this.code] == null) {
        array = [null];
      } else if (!this.msg.includes(item[this.code])) {
        array = [...this.msg, item[this.code]];
        array.forEach((elem, index) => {
          if (elem == null) {
            array.splice(index, 1);
          }
        });
      } else {
        array = [...this.msg];
        array.forEach((elem, index) => {
          if (elem === item[this.code] || elem == null) {
            array.splice(index, 1);
          }
        });
        if (array.length === 0) {
          array = [null];
        }
      }
      this.$emit("changemsg", array);
    },
    //计算tabs是否被选中
    computedClass(item) {
      if (this.msg.includes(item[this.code])) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style lang="less">
.outBox {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
}
.tabs {
  background: #fff;
  color: #666;
  border: 1px solid #ccc;
  padding: 0 13px;
  height: 26px;
  line-height: 24px;
  margin-right: 9px;
  margin-bottom: 10px;
  border-radius: 2px;
}
.active .tabs {
  color: #fff;
  background: #24adfe;
  border: 1px solid #24adfe;
}
</style>